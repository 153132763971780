import { format } from "date-fns";
import "../App.css";
import { useState } from "react";
const api = {
  key: "f9ce1a567f17f08164820382fe92fc93",
  base: "https://api.openweathermap.org/data/2.5/weather",
};

function WeatherApp() {
  const [query, setQuery] = useState("");
  const [weather, setWeather] = useState({});
  const [error, setError] = useState(true);

  const search = (e) => {
    if (e.key === "Enter") {
      setError(undefined);
      fetch(`${api.base}?q=${query}&units=metric&appid=${api.key}`)
        .then((res) => res.json())
        .then((result) => {
          setWeather(result);
          setQuery("");
          setError(result.message);
        });
    }
  };

  const dateBuilder = (d) => {
    const date = format(d, "eeee, c MMMM yyyy");

    return date;
  };

  return (
    <div
      className={
        typeof weather.main != "undefined"
          ? weather.main.temp > 16
            ? "app warm"
            : "app"
          : "app"
      }
    >
      <main>
        <div className="search-box">
          <input
            type="text"
            className="search-bar"
            placeholder="Search..."
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            onKeyPress={search}
          />
        </div>
        {typeof weather.main != "undefined" ? (
          <div>
            <div className="location-box">
              <div className="location">
                {weather.name}, {weather.sys.country}
              </div>
              <div className="date">{dateBuilder(new Date())}</div>
            </div>
            <div className="weather-box">
              <div className="temp">{Math.round(weather.main.temp)}°c</div>
              <div className="weather">{weather.weather[0].main}</div>
            </div>
          </div>
        ) : (
          <div className="location-box location date">{error}</div>
        )}
      </main>
    </div>
  );
}

export default WeatherApp;
